import React from 'react'
import styled from 'styled-components'
import App from 'App'
import Image from 'components/LocalImage'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import SEO from 'components/SEO'
import logo from '../static/svg/logo.svg'
import logoUkraine from '../static/svg/logo_ukraine.svg'
import illustration500 from '../static/svg/500.svg'

const SUPPORT_EMAIL_ADDRESS = 'yourfriends@agencyvista.com'

const StyledText = styled(Text)`
  text-decoration: none;
`

const Error = () => (
  <App isHeaderVisible={false}>
    <SEO
      title="Internal Server Error"
      description="Seems like there was a problem with our servers. Try again later."
    />
    <Flex minHeight="100vh" flexDirection="column" alignItems="center" px="s">
      <Image mt="l" source={logoUkraine} width="100px" />
      <Image my="l" src={illustration500} maxWidth="300px" />
      <H1 mb="m" color="primary">
        Whoops!
      </H1>
      <Box maxWidth="290px" textAlign="center">
        <Text mb="l" fontSize="l">
          Seems like there was a problem with our servers. Try again later.
        </Text>
      </Box>
      <Box mt="auto" mb="xl" textAlign="center">
        <Text color="secondaryText">Something still doesn't look right?</Text>
        <Flex justifyContent="center">
          <Text color="secondaryText">Contact us at &nbsp;</Text>
          <StyledText as="a" href={`mailto:${SUPPORT_EMAIL_ADDRESS}`} color="primary">
            {SUPPORT_EMAIL_ADDRESS}
          </StyledText>
        </Flex>
      </Box>
    </Flex>
  </App>
)

export default Error
